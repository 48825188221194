import 'import/js/components/addtocart/component.addtocart.classic.tpl';
import 'import/js/components/addtocart/component.addtocart.classic.less';

export default angular
  .module('component.addtocart.classic', [])

  .component('buttonAddToCartClassic', {
    require: {
      productCtrl: '?^^productDetail',
      comparatorCtrl: '?^^comparator'
    },
    bindings: {
      isProductButton: '<?',
      isFinderButton: '<?',
      isModulo: '<?',
      isCartButton: '<?',
      isCompositionType: '<?',
      idCompositionType: '<?',
      showQuickView: '<?',
      action: '&?',
      size: '@',
      btnClass: '@',
      inputClass: '@',
      idLine: '<?',
      commentLine: '@?',
      id: '<idProduct',
      productQuantity: '<?',
      isPrimary: '<',
      addToCartActive: '<?',
      allowAddToCart: '<',
      min: '@',
      step: '@',
      price: '<productPrice',
      designation: '@productDesignation',
      img: '@productImg',
      distrimageSrc: '@productDistrimageSrc',
      url: '@productUrl',
      titleAdd: '@?',
      titleAlert: '@?',
      titleDetail: '@?',
      tooltip: '@',
      tooltipAlert: '@?',
      btnTooltip: '@',
      modalCtrl: '<',
      titleResultOk: '@',
      device: '<'
    },
    templateUrl: 'import/js/components/addtocart/component.addtocart.classic.tpl',
    /* @ngInject */
    controller: function ($scope, $injector) {
      const ctrl = this;

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "addtocart.classic.init" */ 'import/js/components/addtocart/component.addtocart.classic.init.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('InitAddtocartClassic');
        service.init(ctrl, $scope);
      };
    }
  });
