import { pullAllBy } from 'lodash-es';

export default angular
  .module('service.cart', [])

  /* @ngInject */
  .factory('CartService', function ($rootScope, $timeout, HttpService, AppService, toastr, GA4Service) {
    const cartRequests = [];

    const service = {
      addToCart,
      deleteLine,
      itemQuantityInCart,
      checkAvailability,
      cartRequests,
      checkCartEnable
    };
    return service;

    ////////////

    function addToCart(products, checkCumulativeProduct = false) {
      const productsToCart = {
        Products: [],
        CheckCumulativeProduct: checkCumulativeProduct
      };

      products.forEach(product => {
        productsToCart.Products.push({
          IDLine: product.idLine || 0,
          IDProduct: product.idProduct || 0,
          Reference: product.Reference || '',
          Quantity: product.quantity,
          Comment: product.Comment || '',
          Elements: product.Elements || null,
          Customization: product.Customization || null,
          CustomizablePrice: product.CustomizablePrice ? { Price: product.CustomizablePrice.current } : null
        });
      });
      const tmpId = new Date().getTime();
      cartRequests.push({ id: tmpId });
      return HttpService.post({
        url: '/Product/AddToCart',
        data: productsToCart,
        keepDataObject: true
      })
        .then(response => {
          pullAllBy(cartRequests, [{ id: tmpId }], 'id');

          try {
            if (products[0]?.quantity > 0) {
              // Add to Cart
              GA4Service.addToCart(response.data?.results?.Cart?.TradeActions, products, response.data?.results?.Currency?.ISOCode, response.data?.affiliation);
            } else {
              // Remove from Cart
              GA4Service.removeFromCart(response.data?.results?.Cart?.TradeActions, products, response.data?.results?.Currency?.ISOCode, response.data?.affiliation);
            }

          } catch (error) {
            console.log(error);
          }

          // Netwave
          if (response.data.status === 'OK' && response.data.results.Site.NETWAVE_ACTIVE && (!products || products !== null) && products.length > 0) {
            if (!products[0].idCategory) {
              HttpService.post({
                url: `/GetCategoryProduct/${products[0].idProduct}`,
                cache: true
              })
                .then(function (getCategoryResponse) {
                  if (products[0].quantity > 0) {
                    window.wsb.addArticleToCart(String(getCategoryResponse.idCategory), String(products[0].idProduct), products[0].quantity, products[0].Price.TTCPrice);
                  } else if (products[0].quantity < 0) {
                    window.wsb.removeArticleFromCart(String(getCategoryResponse.idCategory), String(products[0].idProduct), -(products[0].quantity), products[0].Price.TTCPrice);
                  }
                })
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              if (products[0].quantity > 0) {
                window.wsb.addArticleToCart(String(products[0].idCategory), String(products[0].idProduct), products[0].quantity, products[0].Price.TTCPrice);
              } else if (products[0].quantity < 0) {
                window.wsb.removeArticleFromCart(String(products[0].idCategory), String(products[0].idProduct), -(products[0].quantity), products[0].Price.TTCPrice);
              }
            }
          }
          return response.data;
        })
        .catch(error => {
          throw error;
        });
    }

    function deleteLine(idLine, product, designation, img, imgSrc, message, fromCart) {
      $rootScope.$broadcast('showPageLoader', true);
      HttpService.get({
        url: `/RemoveCartLine/${idLine}`,
        cache: false
      })
        .then(response => {
          if (response.status === 'OK') {
            AppService.updateParams(response.results);
            toastr.success(designation, message, {
              allowHtml: true,
              extraData: {
                template: 'import/js/libs/angular-toastr/toast_addtocart.tpl',
                data: {
                  img,
                  imgSrc
                }
              }
            });

            try {
              // GA4 Remove From Cart
              if (response.status === 'OK' && response.results) {
                const currency = response?.results?.Currency?.ISOCode ? response.results.Currency.ISOCode : null;
                GA4Service.removeFromCart(response.results.Cart.TradeActions, [response.removedProducts], currency, response.results.Localization.TradeName);
              }
            } catch (error) {
              console.error(error);
            }

            // Netwave
            if (response.results.Site.NETWAVE_ACTIVE) {
              if (!product.idCategory) {
                HttpService.post({
                  url: `/GetCategoryProduct/${product.idProduct}`,
                  cache: true
                })
                  .then(function (getCategoryResponse) {
                    if (product.quantity > 0) {
                      window.wsb.removeArticleFromCart(getCategoryResponse.idCategory, String(product.idProduct), product.quantity, product.Price.TTCPrice);
                    } else if (product.quantity < 0) {
                      window.wsb.removeArticleFromCart(getCategoryResponse.idCategory, String(product.idProduct), -(product.quantity), product.Price.TTCPrice);
                    }
                    deleteLineCallback(fromCart, response);
                  })
                  .catch(function (error) {
                    console.error(error);
                  });
              } else {
                if (product.quantity > 0) {
                  window.wsb.removeArticleFromCart(product.idCategory, String(product.idProduct), product.quantity, product.Price.TTCPrice);
                } else if (product.quantity < 0) {
                  window.wsb.removeArticleFromCart(product.idCategory, String(product.idProduct), -(product.quantity), product.Price.TTCPrice);
                }
                deleteLineCallback(fromCart, response);
              }
            }
          } else {
            AppService.getTranslate()
              .then(messages => {
                toastr.warning(messages.errors.TryLater, messages.errors.ErrorHasOccurred, {
                  allowHtml: true,
                  extraData: {
                    template: 'import/js/libs/angular-toastr/toast_message.tpl'
                  }
                });
              })
              .catch(error => {
                console.error(error);
              });
          }
          $rootScope.$broadcast('showPageLoader', false);
        })
        .catch(error => {
          console.error(error);
        });
    }

    function deleteLineCallback(fromCart, response) {
      if (fromCart && !response.results.Cart.Products.length) {
        $rootScope.$broadcast('showPageLoader', true);
        $timeout(() => {
          $rootScope.$broadcast('showPageLoader', true);
        }, 450);
        window.location = $rootScope.backToStore;
      }
    }
    function itemQuantityInCart(id, hash = '') {
      return AppService.getParams()
        .then(data => {
          if (!data.HasCart) return 0;

          let qty = 0;
          data.Cart.Products.forEach(product => {
            if (product.IDProduct === id) {
              if (hash !== '') {
                if (product.Comment === hash) {
                  qty = product.Quantity;
                  return false;
                }
              } else {
                qty = product.Quantity;
                return false;
              }
            }
          });
          return qty;
        })
        .catch(error => {
          console.error(error);
        });
    }

    function checkAvailability() {
      return HttpService.post({
        url: '/Cart/checkAvailability'
      })
        .then(response => response)
        .catch(error => {
          console.error(error);
        });
    }

    function checkCartEnable() {
      return HttpService.post({
        url: '/Cart/CheckCartEnable'
      })
        .then(response => response)
        .catch(error => {
          console.error(error);
        });
    }
  });
