import { inRange } from 'lodash-es';
import cartPostProcess from 'import/js/services/cart.postprocess.js';

export default angular
  .module('service.app', [])

  /* @ngInject */
  .factory('AppService', function ($rootScope, $q, $templateCache, HttpService, ModalService) {
    let appPromise;
    let translate;
    let MobilityVisitorContext;

    const service = {
      getParams,
      resetParams,
      updateParams,
      getTranslate,
      getProductPrice,
      updateStore,
      updateKey,
      emailExists,
      updateSecurityToken
    };
    return service;

    ////////////
    function getParams(refresh) {
      if (refresh || angular.isUndefined(appPromise)) {
        appPromise = HttpService.get({
          url: `/App${refresh ? `?${new Date().getTime()}` : ''}`, // Fix IE11 cache
          keepDataObject: true
        })
          .then(response => {
            $rootScope.$broadcast('updateVisitorContext', response.data.VisitorContext);
            $rootScope.$broadcast('getParams', response.data.VisitorContext.IsLogged);
            let data = angular.copy(response.data.VisitorContext);
            MobilityVisitorContext = response.data.MTYVisitorContext;
            data = { ...data, Mobility: MobilityVisitorContext };
            data.Cart = cartPostProcess(data.Cart);
            clearTemplateCache(refresh);
            return data;
          })
          .catch(error => {
            throw error;
          });
      }
      return appPromise;
    }

    function resetParams() {
      appPromise = undefined;
    }

    function updateParams(data, noevent) {
      data.Cart = cartPostProcess(data.Cart);
      if (!data.Mobility && MobilityVisitorContext) data.Mobility = MobilityVisitorContext;
      appPromise = $q.resolve(data);
      if (noevent) return;
      $rootScope.$broadcast('cartUpdate');
      if (data.Store) $rootScope.$broadcast('storeUpdate', null, { VisitorContext: data });
    }

    function updateSecurityToken(data, token, name = 'securitytoken') {
      if (token) {
        if (data) {
          data.Token = token;
        }
        const hSecurityToken = $(`input:hidden[name=\"${name}\"]`);

        if (hSecurityToken) {
          hSecurityToken.val(token);
        }
      }
    }

    async function getTranslate() {
      if (angular.isUndefined(translate)) {
        try {
          const { data } = await HttpService.get({
            url: '/Template/Translate/Translate',
            keepDataObject: true
          });
          translate = data;
        } catch (error) {
          throw error;
        }
      }
      return translate;
    }

    function getProductPrice(priceObj, qty) {
      if (!priceObj.prices) {
        const prices = [
          {
            qty: 1,
            range: [0],
            HasDiscount: priceObj.HasDiscount,
            Discount: priceObj.Discount,
            HTDiscountedPrice: priceObj.HTDiscountedPrice,
            TTCDiscountedPrice: priceObj.TTCDiscountedPrice,
            HTPrice: priceObj.HTPrice,
            TTCPrice: priceObj.TTCPrice,
            DegressivePrice: priceObj.DegressivePrice
          }
        ];
        if (Object.entries(priceObj.DegressivePrice).length) {
          let i = 0;
          Object.entries(priceObj.DegressivePrice).forEach(([key]) => {
            const item = priceObj.DegressivePrice[key];
            prices[i].range.push(Number(key));

            prices.push({
              qty: Number(key),
              range: [Number(key)],
              HasDiscount: item.HasDiscount,
              Discount: item.Discount,
              HTDiscountedPrice: item.HTDiscountedPrice,
              TTCDiscountedPrice: item.TTCDiscountedPrice,
              HTPrice: item.HTPrice,
              TTCPrice: item.TTCPrice
            });

            i++;
          });
        }
        priceObj.prices = prices;
      }

      let current = {};
      priceObj.prices.forEach(price => {
        if (price.range && inRange(qty, price.range[0], price.range[1] || 10000)) {
          current = price;
          return false;
        }
      });
      return current;
    }

    function updateStore(visitorContext) {
      appPromise.$$state.value.Store = visitorContext.Store;
      appPromise.$$state.value.Visitor.Store = visitorContext.Visitor.Store;
    }

    function updateKey(key, object) {
      appPromise.$$state.value[key] = object;
    }

    async function emailExists({ email, openModal, action }) {
      const { IsLogged } = await getParams();
      if (!IsLogged) {
        try {
          const { status, errors } = await HttpService.post({
            url: '/TestMailExist',
            data: {
              Email: email
            }
          });
          if (status === 'ERROR') {
            const errorKey = Object.keys(errors).find(key => key === 'Global' && errors[key].Errors[0].ErrorMessage === 'AccountExist');
            if (errorKey !== undefined) {
              if (openModal) {
                ModalService.close();
                const options = {
                  mailRecognized: email
                };
                if (action) {
                  options.action = action;
                }
                ModalService.show('/Template/Authentication/ModalAuthentication', options, null, 'loginModalCtrl');
              }

              return {
                exists: true,
                errors: errors[errorKey].Errors
              };
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
      return {
        exists: false
      };
    }

    // Private
    function clearTemplateCache(refresh) {
      if (!refresh) return;

      const keys = [...$templateCache.getKeys()];
      keys.forEach(key => {
        if (key.indexOf('/') === 0 && !key.includes('ModalAddToCart')) {
          $templateCache.remove(key);
        }
      });
    }
  });
