import 'import/js/directives/sticky/directive.sticky.js';

import 'import/js/directives/video/directive.video.player.js';
import 'import/js/directives/tab/directive.tab.equalheights.js';
import 'import/js/directives/flip/directive.flip.js';

/* Comparateur */
import 'Modules/Shared/Comparator/import/component.comparator.js';
import 'Modules/Shared/Comparator/import/directive.compare.checkbox.js';
import 'Modules/Shared/Comparator/import/service.compare.js';
import 'Modules/Shared/Comparator/import/compare.checkbox.less';

/* Commande par ref */
import 'Modules/Cart/import/quick.order.tpl';
import 'Modules/Cart/import/quick.order.popover.tpl';
import 'Modules/Shared/Autocomplete/import/autocomplete.tpl';

/* Devis */
import 'Modules/Quotation/import/service.quotation.js';

/* Recherche */
import 'Modules/Finder/import/directive.autocomplete.finder.js';
import 'Modules/Finder/import/finder.js';

import uiDatepickerPopup from 'angular-ui-bootstrap/src/datepickerPopup';
import 'import/js/directives/nicescroll/directive.nicescroll.js';
import 'import/js/directives/popuponeshot/directive.popuponeshot.js';
import 'import/js/directives/popuponeshot/directive.popuponeshot.less';

export default angular
  .module('project', [
    'directive.sticky',
    'directive.video.player',
    'directive.tab.equalheights',
    'directive.flip',
    'component.comparator',
    'directive.compare.checkbox',
    'service.compare',
    'service.quotation',
    'directive.autocomplete.finder',
    'finder',
    uiDatepickerPopup,
    'directive.nicescroll',
    'directive.popuponeshot'
  ])

  /* @ngInject */
  .config(function (usSpinnerConfigProvider) {
    usSpinnerConfigProvider.setDefaults({
      color: '#344d65',
      lines: 15,
      length: 12,
      width: 2,
      radius: 10,
      opacity: 0
    });
    usSpinnerConfigProvider.setTheme('small', {
      length: 8,
      radius: 5
    });
    usSpinnerConfigProvider.setDelay(400);
  })

  /* @ngInject */
  .config(function (nicescrollConfigProvider) {
    nicescrollConfigProvider.set({
      background: '#f1f5f9',
      cursorborder: 'none',
      cursorcolor: '#c9d3df'
    });
  })

  /* @ngInject */
  .config(function (toastrConfig) {
    angular.extend(toastrConfig, {
      timeOut: 3000,
      closeButton: true,
      closeHtml: '<button><i class="glyphicon glyphicon-remove"></i></button>',
      iconClasses: {
        success: ['success', 'glyphicon glyphicon-ok'],
        error: ['error', 'glyphicon glyphicon-exclamation-sign'],
        info: ['info', 'glyphicon glyphicon-info-sign'],
        warning: ['warning', 'glyphicon glyphicon-exclamation-sign']
      },
      templates: {
        toast: 'import/js/libs/angular-toastr/toast.tpl',
        progressbar: 'import/js/libs/angular-toastr/toast_progressbar.tpl'
      }
    });
  })

  /* @ngInject */
  .config(function (bsValidationConfigProvider) {
    bsValidationConfigProvider.global.setValidateFieldsOn(['blur', 'submit']);
    bsValidationConfigProvider.global.setDisplayErrorsAs('tooltip');
    bsValidationConfigProvider.global.tooltipAppendToBody = false;
    bsValidationConfigProvider.global.errorMessagePrefix = '<span class="glyphicon glyphicon-exclamation-sign"></span> &nbsp;';
  })

  .constant('dropzoneOptions', {
    url: '/Upload',
    uploadMultiple: true,
    parallelUploads: 10,
    maxFilesize: '4',
    addRemoveLinks: true,
    renameFilename: name => `${new Date().getTime()}_!_${name}`,
    dictRemoveFile: '<i class="glyphicon glyphicon-remove"></i>'
  });
