export default angular
  .module('directive.alma.widget', [])

  .directive('almaWidget', function () {
    return {
      restrict: 'E',
      controllerAs: 'almaCtrl',
      bindToController: {
        amount: '<',
        idCard: '<'
      },
      controller: function ($window, RoutesService, HttpService) {
        const ctrl = this;
        const Alma = $window.AlmaWidgets;
        const Widgets = Alma.Widgets;
        let widget;
        let apiInfo;
        let isInit = false;

        ctrl.$onInit = async () => {
          apiInfo = (await getApiInfo()).results.apiInfo;
          const mode = apiInfo.IsProd ? Alma.ApiMode.LIVE : Alma.ApiMode.TEST;

          widget = Widgets.initialize(
            apiInfo.MerchantID, // ID marchand
            mode, // mode de l'API (LIVE ou TEST)
          );

          await setupWidget();
          isInit = true;
        };

        /**
        * Se lance à chaque changement d'un binding (ici amount)
        */
        ctrl.$onChanges = async function (changes) {
          if ((changes.amount && changes.amount.isFirstChange()) || !isInit) return;
          // ne pas lancer au oninit, mais juste quand la valeur du total change
          await setupWidget();
        };

        /**
         * Met en place l'objet widget avec les éligibilités
         */
        async function setupWidget() {
          //en fontion du mode INTEGRE ou pas
          let plans;
          if (apiInfo.IsEmbedded) {
            plans = await getEligibility();
          } else {
            plans = await getInstallment();
          }
          if (plans.length === 0) return;

          widget.add(Widgets.PaymentPlans, {
            container: '#alma-widget',
            purchaseAmount: ctrl.amount * 100,
            locale: 'fr',
            hideIfNotEligible: true,
            transitionDelay: 5500,
            plans
          });
        }

        /**
         * Récupère les informations de disponibilité des différentes échéances
         */
        async function getEligibility() {
          let url = `${RoutesService.getUrlByName('AlmaCheckEligibility')}?`;
          if (ctrl.idCard) url += `IDCard=${ctrl.idCard}&`;
          url += `Amount=${ctrl.amount}`;
          let allowedPayments;

          await HttpService.get({
            url,
            cache: false
          })
            .then(response => {
              if (response.status === 'OK') {
                allowedPayments = response.results.Eligibilities;
              }
            })
            .catch(error => {
              console.error(error);
            });

          // on ne récupère que les paiements éligibles
          const plans = allowedPayments.filter(e => Boolean(e.eligible))
            .map(e => ({ installmentsCount: e.installments_count, minAmount: e.min_amount * 100, maxAmount: e.max_amount * 100 }));

          return plans;
        }

        async function getInstallment() {
          const url = `${RoutesService.getUrlByName('AlmaInstallmentsEligibility')}?`;
          let allowedPayments;

          await HttpService.get({
            url,
            cache: false
          })
            .then(response => {
              if (response.status === 'OK') {
                allowedPayments = response.results.Installments;
              }
            })
            .catch(error => {
              console.error(error);
            });

          const plans = allowedPayments
            .map(e => ({ installmentsCount: e.installments_count, minAmount: e.min_purchase_amount, maxAmount: e.max_purchase_amount }));

          return plans;
        }

        /**
         * Récupère les infos api
         */
        async function getApiInfo() {
          const url = RoutesService.getUrlByName('AlmaGetApiInfos');
          const response = HttpService.get({
            url,
            cache: true
          });
          return response;
        }
      }
    };
  });
