export default angular
  .module('controller.newsletter', [])

  /* @ngInject */
  .controller('newsletterController', function ($rootScope, $element, $timeout, HttpService, AppService, LogService, ModalService, toastr) {
    const ctrl = this;

    ctrl.formData = {};

    ctrl.submit = async () => {
      ctrl.loading = true;

      try {
        //Prise en compte du token de securité des formulaires
        ctrl.formData.Token = $("input:hidden[name='securitytoken_newsletter']").val();

        const response = await HttpService.post({
          url: '/Newsletter/Subscribe',
          data: ctrl.formData
        });
        const { messages, errors } = await AppService.getTranslate();
        if (response.status === 'OK') {
          ctrl.submitted = true;
          toastr.success('', messages.NewsletterOK, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
          $timeout(() => {
            ctrl.form.$hideValidation();
            $element.find('.form-control').blur();
            ctrl.formData.Email = '';
            ctrl.form.$resetValidation();
          }, 2000);
        } else if (response.status === 'ERROR_CONNECTION_EXPECTED') {
          LogService.redirect = {
            url: null
          };
          ModalService.show(
            '/Template/Authentication/ModalAuthentication',
            {
              newsletterConnect: true,
              mailRecognized: ctrl.formData.Email
            },
            null,
            'loginModalCtrl'
          );
        } else if (response.status === 'REGISTRATION_ALREADY_EXISTS') {
          toastr.success('', messages.NewsletterDejaOK, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
        } else {
          Object.keys(response.errors).forEach(key => {
            toastr.warning('', errors[response.errors[key].Errors[0].ErrorMessage], {
              allowHtml: true,
              extraData: {
                template: 'import/js/libs/angular-toastr/toast_message.tpl'
              }
            });
          });
        }
      } catch (error) {
        console.error(error);
      }
      ctrl.loading = false;
      !$rootScope.$$phase && $rootScope.$digest();
    };
  });
