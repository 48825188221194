import { kebabCase } from 'lodash-es';
export default angular
  .module('finder.service.parse', [])

  /* @ngInject */
  .factory('FinderParseService', function ($rootScope, $filter, AppService, PriceService, AvailabilityService) {
    const service = {
      parse
    };
    return service;

    ////////////
    async function parse(search, items) {
      return Promise.all(
        items.map(async item => {
          const obj = {
            designationHtml: $filter('matchstring')(item.Designation, search)
          };
          obj.Designation = item.Designation;
          obj.Reference = item.Reference;
          obj.ReviewsInfo = item.ReviewsInfo ? { Count: item.ReviewsInfo.Count, Average: Math.floor(item.ReviewsInfo.Average) } : null;
          obj.HTMLShortDescription = item.HTMLShortDescription;
          obj.Price = item.Price;
          obj.MinSaleQuantity = item.MinSaleQuantity !== 0 ? item.MinSaleQuantity : 1;
          obj.ModuloSaleQuantity = item.ModuloSaleQuantity !== 0 ? item.ModuloSaleQuantity : 1;
          obj.IsGroupingProduct = item.IsGroupingProduct;
          obj.IsNumeric = item.IsNumeric;
          obj.IsCustomizable = item.IsCustomizable;
          obj.AllowAddToCart = item.AllowAddToCart;
          obj.Themes = item.Themes;
          obj.KeyWebAspect = item.KeyWebAspect;
          obj.KeyProductType = item.KeyProductType;
          obj.CompositionType = item.CompositionType;
          obj.isCompositionType = item.CompositionType !== 0 ? true : false;
          obj.Unit = item.Unit;
          obj.UnitDesignation = item.UnitDesignation.toLowerCase();
          obj.Properties = item.Properties.reduce((result, prop) => {
            result[prop.KeyProperty] = prop.Value;
            return result;
          }, {});

          obj.isComparable = item.Themes.some(th => th.KeyTheme === 'Comparateur');
          obj.Pictogrammes = item.Themes?.filter(th => th.KeyRegrouping === 'Pictogrammes')?.map(th => ({
            IDTheme: th.IDTheme,
            IDPicture: th.IDPicture,
            Designation: th.Designation,
            Key: th.KeyTheme,
            Background: th.Text1,
            Color: th.Text2
          }));

          obj.IDVignettePicture = 0;
          if (item.SubPictures.length > 0) {
            obj.SubPictures = item.SubPictures;
            obj.IDVignettePicture = obj.SubPictures.find(sp => sp.ProductPictureType === 'Vignette')?.IDPicture;
            if (obj.IDVignettePicture === null || obj.IDVignettePicture === undefined) {
              obj.IDVignettePicture = 0;
            }
          }

          if (item.Availability) {
            obj.Availability = item.Availability;
            const { Site } = await AppService.getParams();
            if (Site.DisplayAvailabilityOnListPage && Site.IsOmnichannelOrder) {
              await AvailabilityService.formatMessage(obj.Availability.Detail, 'Delivery');
              await AvailabilityService.formatMessage(obj.Availability.Detail, 'PickUp');
              obj.Availability.Detail.modalData = {
                idProduct: item.IDProduct,
                action: $rootScope.forceReload,
                quantity: item.MinSaleQuantity !== 0 ? item.MinSaleQuantity : 1
              };
            }
          }

          obj.Attributes = item.Attributes;
          const conditioningList = [];
          obj.Attributes.forEach(att => {
            att.designationKey = kebabCase(att.Designation);
            att.Values.forEach(value => {
              if (!conditioningList.includes(value.IDProduct)) {
                conditioningList.push(value.IDProduct);
              }
            });
          });
          obj.CountAttributes = conditioningList.length;

          obj.isModulo = item.ModuloSaleQuantity !== 1 && item.ModuloSaleQuantity !== 0 || item.MinSaleQuantity !== 1 && item.MinSaleQuantity !== 0 ? 'true' : 'false';
          obj.htmlPrice = await PriceService.get(item);

          return {
            IDProduct: item.IDProduct,
            URL: item.URL,
            IDPicture: item.MainPicture?.IDPicture || item.IDPicture,
            ...obj
          };
        })
      );
    }
  });
