export default angular
  .module('directive.top.promos', [])

  /* @ngInject */
  .directive('topPromos', function () {
    return {
      restrict: 'E',
      scope: true,
      bindToController: {
        count: '<'
      },
      controllerAs: 'ctrl',
      /* @ngInject */
      controller: function ($element, $rootScope, $timeout, $interval, $cookies) {
        const ctrl = this;
        let interval;

        ctrl.$onInit = () => {
          if (ctrl.count === 0) return;

          ctrl.index = 0;

          const cookiesTopPromos = $cookies.get('cookieTopPromos');

          $timeout(() => {
            ctrl.active = cookiesTopPromos ? 2 : 1;
            $timeout(
              () => {
                $rootScope.$broadcast('topUpdate');
              },
              0,
              false
            );
            if (!cookiesTopPromos) {
              if (ctrl.count > 1) startAnimate();
              resize();
            } else {
              $element.parent().parent().addClass('top-promos-closed');
            }
          }, 0);
        };

        ctrl.close = () => {
          ctrl.active = 2;
          $element.parent().parent().addClass('top-promos-closed');
          $timeout(
            () => {
              $rootScope.$broadcast('topUpdate');
            },
            400,
            false
          );
          stopAnimate();
          $timeout(() => { }, 600, false);
          const now = new Date();
          const exp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
          $cookies.put('cookieTopPromos', 'true', {
            path: '/',
            expires: exp
          });
        };

        ctrl.open = () => {
          ctrl.active = 1;
          $element.parent().parent().removeClass('top-promos-closed');
          $timeout(
            function () {
              $rootScope.$broadcast('topUpdate');
            },
            400,
            false
          );
          $cookies.remove('cookieTopPromos');
          startAnimate();
          resize();
        };

        // Functions
        function startAnimate() {
          interval = $interval(() => {
            ctrl.index = ctrl.index === ctrl.count - 1 ? 0 : ctrl.index + 1;
          }, 5000);

          ctrl.$onDestroy = () => {
            stopAnimate();
          };
        }

        function stopAnimate() {
          $interval.cancel(interval);
        }

        function resize() {
          $timeout(
            () => {
              let h = 0;
              $element
                .find('.inner')
                .children('.item')
                .each(function () {
                  h = Math.max(h, $(this).outerHeight());
                });
              $element.find('.inner').height(h);
            },
            400,
            false
          );
        }
      }
    };
  });
