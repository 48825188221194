export default angular
  .module('directive.mini-cart', [])

  .directive('miniCart', function () {
    return {
      restrict: 'E',
      bindToController: {
        isAddtocartNotlogged: '<'
      },
      /* @ngInject */
      controller: function ($scope, $element, $filter, $location, AppService, CartService, RoutesService, ModalService) {
        const ctrl = this;

        // Translate
        const tplTranslate = {};
        $element
          .children('.translate')
          .children()
          .each(function () {
            const $this = $(this);
            tplTranslate[$this.attr('key')] = $this.html();
          });

        getCart(false);

        $scope.$on('logUpdate', (event, value) => {
          ctrl.addtocartOK = ctrl.isAddtocartNotlogged && !value ? false : true;
        });

        ctrl.emptyCart = () => {
          ModalService.show('/Template/Cart/ModalClearCartConfirm', {}, null, 'clearCartConfirmModalCtrl');
        };

        ctrl.remove = line => {
          const product = {
            idProduct: line.Product.IDProduct,
            quantity: line.Quantity,
            Price: {
              TTCPrice: line.TTCAmount
            }
          };
          ModalService.show(
            '/Template/Cart/ModalDeleteConfirm',
            {
              action: () => {
                CartService.deleteLine(line.IDLine, product, line.Product.Designation, line.Product.IDPicture, line.Product.DistrimageSrc, tplTranslate.titleDeleteOk);
              }
            },
            null,
            'deleteConfirmModalCtrl'
          );
        };

        $scope.$on('cartUpdate', () => {
          getCart(false);
        });

        $scope.$on('cartUpdateWithRefresh', () => {
          getCart(true);
        });

        async function getCart(withRefresh) {
          ctrl.cartIsActive = true;
          const params = await AppService.getParams(withRefresh);
          if (params.Agent && !params.Mobility.Profile.AllModules.find(p => p.ModuleKey === 'sale')?.IsActive) {
            ctrl.cartIsActive = false;
          }
          const cartPath = $location.path() !== `/${RoutesService.getUrlByName('QuickOrder')}` ? `/${RoutesService.getUrlByName('Cart')}` : `/${RoutesService.getUrlByName('QuickOrder')}`;
          ctrl.hasCart = params.HasCart && params.Cart && params.Cart.Products && params.Cart.Products.length;
          ctrl.cartLink = !ctrl.hasCart ? '' : cartPath;
          ctrl.showTTCPrice = params.Visitor.CardType.ShowTTCPrice;
          ctrl.addtocartOK = ctrl.isAddtocartNotlogged && !params.IsLogged ? false : true;
          ctrl.count = 0;
          if (ctrl.hasCart) {
            ctrl.data = params.Cart;
            params.Cart.Products.forEach(line => {
              if (params.Cart.TradeActions.find(tradeAction => tradeAction.IDProduct === line.IDProduct)) {
                line.isGift = true;
              }
            });
            ctrl.cartProducts = $filter('orderBy')(
              params.Cart.Products.filter(line => !line.isGift),
              'LineNumber'
            );
            const count = parseFloat(ctrl.cartProducts.reduce((result, line) => result + line.Quantity, 0));
            ctrl.count = count.toFixed(count % 1 === 0 ? 0 : 2);
          }
          !$scope.$$phase && $scope.$digest();
        }
      },
      controllerAs: 'miniCartCtrl'
    };
  })

  /* @ngInject */
  .controller('deleteConfirmModalCtrl', function (data, device, options, ModalService) {
    const ctrl = this;

    ctrl.submit = () => {
      ModalService.close();
      data.action();
    };
    ctrl.cancel = () => {
      ModalService.close();
      if (data.cancel) data.cancel();
    };
  })

  /* @ngInject */
  .controller('clearCartConfirmModalCtrl', function (data, $rootScope, HttpService, AppService, ModalService, toastr, GA4Service) {
    const ctrl = this;

    ctrl.submit = async () => {
      ModalService.close();
      $rootScope.$broadcast('showPageLoader', true);

      try {
        const response = await HttpService.post({
          url: 'Cart/ClearCart',
          data: {}
        });
        try {
          // GA4 Remove From Cart
          if (response.status === 'OK') {
            const currency = response?.results?.Currency?.ISOCode ? response.results.Currency.ISOCode : null;
            GA4Service.removeFromCart([], response?.removedProducts, currency, response?.results?.Localization?.TradeName);
          }
        } catch (error) {
          console.error(error);
        }
        const { messages, errors } = await AppService.getTranslate();
        if (response.status === 'OK') {
          AppService.updateParams(response.results);
          toastr.success('', messages.ClearCartOK, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
          if (response.removedProducts) {
            // Netwave
            if (response.results.Site.NETWAVE_ACTIVE) {
              response.removedProducts.forEach(function (product) {
                if (!product.IDCategory) {
                  HttpService.post({
                    url: `/GetCategoryProduct/${product.IDProduct}`,
                    cache: true
                  })
                    .then(function (getCategoryResponse) {
                      if (product.Quantity > 0) {
                        window.wsb.removeArticleFromCart(getCategoryResponse.idCategory, String(product.IDProduct), product.Quantity, product.Price.TTCPrice);
                      } else if (product.Quantity < 0) {
                        window.wsb.removeArticleFromCart(getCategoryResponse.idCategory, String(product.IDProduct), -product.Quantity, product.Price.TTCPrice);
                      }
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                } else {
                  if (product.Quantity > 0) {
                    window.wsb.removeArticleFromCart(product.IDCategory, String(product.IDProduct), product.Quantity, product.Price.TTCPrice);
                  } else if (product.Quantity < 0) {
                    window.wsb.removeArticleFromCart(product.IDCategory, String(product.IDProduct), -product.Quantity, product.Price.TTCPrice);
                  }
                }
              });
            }
            if (data.action) data.action();
          } else {
            if (data.action) data.action();
          }
        } else {
          toastr.warning(errors.TryLater, errors.ErrorHasOccurred, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
        }
      } catch (error) {
        console.error(error);
      }

      $rootScope.$broadcast('showPageLoader', false);
      !$rootScope.$$phase && $rootScope.$digest();
    };
  });
